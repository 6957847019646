















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { WorkOrderConfigEntityModel, WorkOrderConfigQueryModel } from '@/entity-model/work-order-config-entity';
import WorkOrderConfigListService from '@/service/work-order-config-list';

@Component
export default class WorkOrderServiceListComponent extends TableDialogFormComponent<WorkOrderConfigEntityModel, WorkOrderConfigQueryModel> {
    WorkOrderConfigEntityModel = WorkOrderConfigEntityModel;

    created() {
        this.initTable({
            service: WorkOrderConfigListService,
            queryModel: new WorkOrderConfigQueryModel(),
            tableColumns: WorkOrderConfigEntityModel.getTableColumns()
        });

        this.getList();
    }

    configClick(record) {
        this.$router.push(`/dashboard/work-order-service/${record.id}`);
    }
}
